import Clipboard from 'clipboard'

// hacky way to get the internal constructor we need
const ClipboardAction = (() => {
  const c = new Clipboard(window.document.createElement('button'), {
    action: () => {},
    target: () => {},
    text: () => {},
  })
  c.onClick({})
  return c.clipboardAction.constructor
})()

let success
export function copyToClipboard(value){
  if (!Clipboard.isSupported())
    alert('clipboard not supported') // eslint-disable-line no-alert
  const copy = new ClipboardAction({
    action: 'copy',
    text: value,
    container: window.document.body,
    emitter: {
      emit(result){
        success = result === 'success'
        if (!success) prompt('Select to copy:', value) // eslint-disable-line no-alert
      }
    },
  })
  copy.destroy()
  return success
}
