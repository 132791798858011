import { setSession } from 'resources/session'
import { setPageError } from 'resources/pageAlerts'
import api from '../api'

export async function add({ type, value }){
  if (this.getState().addingExternalIdentity) return

  this.setState({
    addingExternalIdentity: value,
    addingExternalIdentityError: undefined,
    verifiedExternalIdentity: undefined,
    externalIdentityVerificationCodeSent: undefined,
    resendExternalIdentityVerificationCodeError: undefined,
    deletingExternalIdentityError: undefined,
  })

  try {
    const { externalIdentity } = await api.addExternalIdentity({ type, value })
    const externalIdentities = {
      ...this.getState().externalIdentities,
      [externalIdentity.value]: { type, verified: false },
    }
    this.setState({
      externalIdentityVerificationCodeSent: externalIdentity.value,
      externalIdentities,
    })
  } catch(addingExternalIdentityError) {
    this.setState({ addingExternalIdentityError })
  } finally {
    this.setState({ addingExternalIdentity: undefined })
  }
}

export async function verify({ verificationCode }) {
  const { verifyingExternalIdentity } = this.getState()

  if (verifyingExternalIdentity) return

  this.setState({
    verifyingExternalIdentity: true,
    verifiedExternalIdentity: undefined,
    errorVerifyingExternalIdentity: undefined,
  })

  try {
    const {
      session,
      externalIdentity,
      justSignedUp,
    } = await api.verifyExternalIdentity({ verificationCode })

    const externalIdentities = { ...this.getState().externalIdentities }
    externalIdentities[externalIdentity.value] = externalIdentity

    this.setState({
      verifiedExternalIdentity: justSignedUp ? undefined : externalIdentity.value,
      externalIdentities,
    })
    if (justSignedUp) sessionStorage['justSignedUp'] = 'true'
    if (session) setSession(session)

  } catch (errorVerifyingExternalIdentity) {
    // this.setState({ errorVerifyingExternalIdentity })
    setPageError('Verification failed', errorVerifyingExternalIdentity)
  } finally {
    this.setState({ verifyingExternalIdentity: undefined })
  }
}

export async function load() {
  if (this.getState().gettingExternalIdentities) return

  this.setState({
    gettingExternalIdentities: true,
    gettingExternalIdentitiesError: undefined,
  })

  try {
    const { externalIdentities } = await api.getExternalIdentities()
    this.setState({ externalIdentities })
  } catch(gettingExternalIdentitiesError) {
    this.setState({ gettingExternalIdentitiesError })
  } finally {
    this.setState({ gettingExternalIdentities: undefined })
  }
}

async function _delete({ value }){
  if (this.getState().deletingExternalIdentity) return

  this.setState({
    deletingExternalIdentity: value,
    deletingExternalIdentityError: undefined,
    addingExternalIdentityError: undefined,
    externalIdentityVerificationCodeSent: undefined,
    resendExternalIdentityVerificationCodeError: undefined,
    makingExternalIdentityPrimeError: undefined,
    verifiedExternalIdentity: undefined,
  })

  try {
    await api.deleteExternalIdentity({ value })
    const externalIdentities = {
      ...this.getState().externalIdentities,
    }
    delete externalIdentities[value]
    this.setState({ externalIdentities })
  } catch(deletingExternalIdentityError) {
    this.setState({ deletingExternalIdentityError })
  } finally {
    this.setState({ deletingExternalIdentity: undefined })
  }
}

export { _delete as delete }

export async function resendVerificationCode({ value }){
  if (this.getState().sendingExternalIdentityVerificationCode) return

  this.setState({
    sendingExternalIdentityVerificationCode: true,
    resendExternalIdentityVerificationCodeError: undefined,
    loginError: undefined,
    errorVerifyingExternalIdentity: undefined,
    verifiedExternalIdentity: undefined,
  })

  try {
    await api.resendExternalIdentityVerificationCode({ value })
    this.setState({ externalIdentityVerificationCodeSent: value })
  } catch(resendExternalIdentityVerificationCodeError) {
    this.setState({ resendExternalIdentityVerificationCodeError })
  } finally {
    this.setState({ sendingExternalIdentityVerificationCode: undefined })
  }
}
