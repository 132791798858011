import { h } from 'preact'
import { useMemo, useCallback } from 'preact/hooks'

import pathnameMatcher from 'lib/pathnameMatcher'

import PageLoadingPage from '../PageLoadingPage'
import OrganizationHomePage from '../OrganizationHomePage'
import OrganizationAnnouncementsPage from '../OrganizationAnnouncementsPage'
import OrganizationSisaPage from '../OrganizationSisaPage'
import OrganizationPersonalDataPage from '../OrganizationPersonalDataPage'
import OrganizationSisaEventPage from '../OrganizationSisaEventPage'
import OrganizationAdminPage from '../OrganizationAdminPage'
import OrganizationCalendarPage from '../OrganizationCalendarPage'

// Lazy loaded pages
const lazy = loader => PageLoadingPage.forImport(loader, { loadingType: 'block' })
const BuyingInterestsPage = lazy(() =>
  import(/* webpackChunkName: "marketplace" */ '../BuyingInterestsPage')
)
const ProductsAndServicesPage = lazy(() =>
  import(/* webpackChunkName: "marketplace" */ '../ProductsAndServicesPage')
)
const OrganizationCovidSymptomTrackerPage = lazy(() =>
  import(/* webpackChunkName: "OrganizationCovidSymptomTrackerPage" */ '../OrganizationCovidSymptomTrackerPage')
)
const DataYogiAdsPage = lazy(() =>
  import(/* webpackChunkName: "DataYogiAdsPage" */ '../DataYogiAdsPage')
)

const OrganizationFeedPage
  = lazy(() => import(/* webpackChunkName: "OrganizationFeedPage" */ '../OrganizationFeedPage'))
const OrganizationForumPage
  = lazy(() => import(/* webpackChunkName: "OrganizationForum" */ '../OrganizationForumPage'))
const OrganizationForumPostPage
  = lazy(() => import(/* webpackChunkName: "OrganizationForum" */ '../OrganizationForumPostPage'))
const OrganizationChatPage
  = lazy(() => import(/* webpackChunkName: "OrganizationChatPage" */ '../OrganizationChatPage'))
const OrganizationMembersPage
  = lazy(() => import(/* webpackChunkName: "OrganizationMembersPage" */ '../OrganizationMembersPage'))
const OrganizationNetworkPage
  = lazy(() => import(/* webpackChunkName: "OrganizationNetworkPage" */ '../OrganizationNetworkPage'))

export function useOrganizationPagePages({
  orgIsClosed,
  orgIsPrivate,
  userIsLoggedIn,
  userIsMember,
  userIsCurator,
  userIsAdmin,
  forumMembersOnly,
}){
  const pages = useMemo(
    () => {
      const pages = getPages({orgIsClosed, orgIsPrivate, forumMembersOnly})
      for (const page of pages) page.unauthorized = (
        (page.reqs.isLoggedIn && !userIsLoggedIn) ? 'notLoggedIn' :
        (page.reqs.isMember && !userIsMember) ? 'notMember' :
        (page.reqs.isCurator && !userIsCurator) ? 'notCurator' :
        (page.reqs.isAdmin && !userIsAdmin) ? 'notAdmin' :
        undefined
      )
      return pages
    },
    [orgIsClosed, orgIsPrivate, userIsMember, userIsAdmin, userIsCurator],
  )
  const findPage = useCallback(
    subpath => {
      let params
      let page = pages.find(page =>
        params = pathnameMatcher.match(subpath, page.route)
      )
      return {page, params}
    },
    [pages],
  )
  return { pages, findPage }
}


function getPages({orgIsClosed, orgIsPrivate, forumMembersOnly}){
  const membersPageRoutes = [
    {
      title: 'Members',
      route: '/members',
      comp: OrganizationMembersPage,
      reqs: {isMember: true},
    },
    {
      title: 'Members',
      route: '/members/@:username',
      comp: OrganizationMembersPage,
      reqs: {isMember: true},
    },
  ]
  return [
    {
      title: 'Home',
      route: '/',
      comp: OrganizationHomePage,
      reqs: {},
    },
    {
      title: 'SISA',
      route: '/sisa',
      comp: OrganizationSisaPage,
      reqs: {isLoggedIn: true},
    },
    {
      route: '/sisa-events/:sisaEventId',
      comp: OrganizationSisaEventPage,
      reqs: {isLoggedIn: true},
    },
    {
      title: 'My Data',
      route: '/my-data',
      comp: OrganizationPersonalDataPage,
      reqs: {isLoggedIn: true},
    },
    {
      title: 'Admin',
      route: '/admin/:path*',
      comp: OrganizationAdminPage,
      reqs: {isCurator: true},
    },
    {
      title: 'Calendar',
      route: '/calendar',
      comp: OrganizationCalendarPage,
      reqs: {},
    },
    {
      title: 'Announcements',
      route: '/ancmt',
      comp: OrganizationAnnouncementsPage,
      reqs: {isMember: true},
    },
    ...(isDataYogi ? [
      {
        title: 'Marketplace',
        route: '/marketplace/:path*',
        comp: BuyingInterestsPage,
        reqs: { isLoggedIn: true },
      },
      {
        title: 'My Stuff',
        route: '/my-stuff/:path*',
        comp: ProductsAndServicesPage,
        reqs: { isLoggedIn: true },
      },
      {
        title: 'Ads',
        route: '/ads/:path*',
        comp: DataYogiAdsPage,
        reqs: { isLoggedIn: true },
      },
      ...membersPageRoutes,
      {
        title: 'Covid Symptom Tracker',
        route: '/covid/:path*',
        comp: OrganizationCovidSymptomTrackerPage,
        reqs: { isLoggedIn: true },
      },
    ] : [
      {
        title: 'Published',
        route: '/published',
        comp: OrganizationFeedPage,
        reqs: {isMember: !!orgIsClosed},
      },
      {
        title: 'Forum',
        route: '/forum/(new|top)?',
        comp: OrganizationForumPage,
        reqs: {loggedIn: true, isMember: !!orgIsPrivate || forumMembersOnly},
      },
      {
        title: 'Internal Post',
        route: '/forum/:feedPostUid',
        comp: OrganizationForumPostPage,
        reqs: {loggedIn: true, isMember: !!orgIsPrivate || forumMembersOnly},
      },
      {
        title: 'Chat',
        route: '/chat/:channel?',
        comp: OrganizationChatPage,
        reqs: {isMember: true},
      },
      ...membersPageRoutes,
      {
        title: 'Network',
        route: '/network/:path*',
        comp: OrganizationNetworkPage,
        reqs: {isLoggedIn: true, isMember: !!(orgIsPrivate || orgIsClosed)},
      },
    ]),
  ]
}
