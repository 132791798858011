import { h } from 'preact'
import PropTypes from 'prop-types'

import useToggle from 'lib/useToggleHook'
import classNames from 'lib/classNames'

import HeaderWithButtons from 'components/HeaderWithButtons'
import Button from 'components/Button'
import Icon from 'components/Icon'
import './index.sass'

export default function HeaderedContent({
  collapsed,
  toggle,
  ...props
}){
  if (toggle && typeof collapsed === 'boolean') return <Content {...{
    ...props,
    collapsed,
    toggle,
  }} />

  return <NonControlledHeaderedContent {...{
    ...props,
    collapsed,
  }}/>
}

function NonControlledHeaderedContent({ collapsedByDefault, collapsable, ...props }) {
  const [collapsed, , , toggle] = useToggle(collapsable && collapsedByDefault)

  return <Content {...{
    ...props,
    collapsable,
    collapsed,
    toggle,
  }} />
}

function Content({
  padded = false,
  size = 'md',
  onClick,
  toggle,
  collapsable,
  children,
  buttons = [],
  className,
  icon,
  header,
  collapsed,
  interfaceHelp,
  ...props
}) {
  if (collapsable){
    onClick = toggle
    buttons = [
      ...Array.from(buttons),
      <Button onClick={toggle} className="HeaderedContent-collapse">
        <Icon type="right-open" rotate={collapsed ? 0 : 90} transitionRotation/>
      </Button>
    ]
  }

  const hasContent = !collapsed && typeof children === 'object'
  return <div {...{
    className: classNames(
      'HeaderedContent',
      { className, padded, collapsed: (collapsable ? !!collapsed : undefined) }
    ),
    ...props
  }}>
    <HeaderWithButtons {...{
      buttons,
      size,
      underlined: hasContent,
      onClick,
      interfaceHelp,
    }}>
      {icon && <Icon type={icon} blue size="lg" className="HeaderedContent-icon"/>}
      {header}
    </HeaderWithButtons>
    <div className="HeaderedContent-content">{children}</div>
  </div>
}

HeaderedContent.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  header: PropTypes.string,
  buttons: PropTypes.node,
  collapsable: PropTypes.bool,
  collapsedByDefault: PropTypes.bool,
  children: PropTypes.node,
  size: HeaderWithButtons.propTypes.size,
  onClick: PropTypes.func,
  toggle: PropTypes.func,
  padded: PropTypes.bool,
  collapsed: PropTypes.bool,
  interfaceHelp: PropTypes.node,
}
