import { useEffect, useCallback } from 'preact/hooks'
import useForceUpdate from 'lib/useForceUpdate'
import { _useToggle } from 'lib/useToggleHook'
import storageBus from 'lib/storageBus'

export function getJSONItem(storage, key, initialValue) {
  return key in storage
    ? safeJSONParse(storage, key)
    : initialValue
}

export function setJSONItem(storage, key, newValue){
  storageBus.setItem(storage, key, JSON.stringify(newValue))
}

export function setLocalStorage(key, newValue) {
  return setJSONItem(localStorage, key, newValue)
}

export function setSessionStorage(key, newValue) {
  return setJSONItem(sessionStorage, key, newValue)
}

export function getLocalStorageState(key, defaults){
  return getJSONItem(localStorage, key, defaults)
}

export function useStorage(storage, key, defaults){
  const forceUpdate = useForceUpdate()

  const getValue = () => getJSONItem(storage, key, defaults)

  const setValue = useCallback(
    value => {
      setJSONItem(
        storage,
        key,
        value,
      )
    },
    [storage, key]
  )

  useEffect(
    () => {
      const onChange = event => {
        if (event.storageArea !== storage || event.key !== key) return
        forceUpdate()
      }
      return storageBus.subscribe(onChange)
    },
    [storage, key],
  )

  return [getValue(), setValue, getValue]
}

export function useLocalStorage(...args){
  return useStorage(global.localStorage, ...args)
}

export function useSessionStorage(...args){
  return useStorage(global.sessionStorage, ...args)
}

export function useStorageState(storage, key, defaults){
  let [state = {}, setState] = useStorage(storage, key, defaults)
  const mergeState = useCallback(
    (changes, replace) => {
      let newState = replace ? changes : {...state, ...changes}
      if (newState) {
        newState = JSON.parse(JSON.stringify(newState))
        if (Object.keys(newState).length === 0) newState = undefined
      }
      setState(state = newState)
    },
    [setState]
  )
  const reset = useCallback(
    () => { mergeState({}, true) },
    [mergeState]
  )
  return [state, mergeState, reset]
}

export function useLocalStorageState(...args){
  return useStorageState(global.localStorage, ...args)
}

export function useSessionStorageState(...args){
  return useStorageState(global.sessionStorage, ...args)
}

export function useLocalStorageToggle(key, defaultValue) {
  const [isOn, setValue] = useLocalStorage(key, defaultValue)
  return _useToggle(isOn, setValue)
}

function safeJSONParse(storage, key){
  try{
    return JSON.parse(storage[key])
  }catch(error){
    console.warn('useStorage hook failed to parse storage key as json', {key, value: storage[key]})
    return undefined
  }
}
