import { isLoggedIn } from 'resources/auth'
import { replaceLocation } from 'resources/location'
import api from '../api'
import { loadEntity } from 'lib/actionHelpers'
import { getBrowserNotificationSettings, createBrowserNotification } from 'lib/browserNotifications'

export function loadSynopses(){
  if (isLoggedIn()) return loadEntity.call(this, {
    reload: true,
    entityKey: 'chatSynopsis',
    request: async () => {
      const newChatSynopsis = await api.getChatSynopsis()
      createBrowserNotificationsForNewChatMessages(
        newChatSynopsis,
        this.getState().chatSynopsis,
      )
      return newChatSynopsis
    },
  })
  this.setState({ chatSynopsis: undefined })
}

function createBrowserNotificationsForNewChatMessages(newChatSynopsis, oldChatSynopsis){
  if (
    window.document.hasFocus() ||
    !oldChatSynopsis ||
    !getBrowserNotificationSettings().recieveChatMessageBrowser
  ) return
  for (const group of newChatSynopsis){
    if (!group.unread) continue
    createBrowserNotification({
      uuid: `newChatMessages-${group.uid}-${group.latestAt}`,
      title: `new chat messages`,
      body: `new chat messages in ${group.organizationApikey} ${group.type}`,
      destinationPath: `/${group.organizationApikey}/chat/${group.uid}`,
    })
  }
}

export function loadChatChannel(chatChannelUid){
  return loadEntity.call(this, {
    reload: true,
    entityKey: `chat:channel:${chatChannelUid}`,
    request: () => api.getChatChannel(chatChannelUid)
  })
}

const didsToString = dids => dids.sort().join(',')

export async function createChatChannel({
  organizationApikey, type, memberUserDids
}){
  if (type !== 'dm') throw new Error(`chatChannel.type can only be "dm"`)
  if (!Array.isArray(memberUserDids) || memberUserDids.length === 0)
    throw new TypeError(`memberUserDids must be a non-blank array of public profile dids`)
  const { chatSynopsis = [] } = this.getState()
  const uuid = didsToString(memberUserDids)
  let chatChannel = chatSynopsis
    .find(c => c.memberUserDids && didsToString(c.memberUserDids) === uuid)
  if (!chatChannel) chatChannel =
      await api.createChatChannel({organizationApikey, type, memberUserDids})
  await replaceLocation(`/${organizationApikey}/chat/${chatChannel.uid}`)
  await this.takeAction('chat.loadSynopses')
}

export function loadMessages(chatChannelUid){
  return loadEntity.call(this, {
    reload: true,
    entityKey: `chat:channel:${chatChannelUid}:messages`,
    request: () => api.getChatMessages(chatChannelUid),
  })
}

let fasterChatPolling = false
let pollingForSynopsisTimeout
export function startPollingForSynopsis(){
  if (pollingForSynopsisTimeout) return
  const frequency = fasterChatPolling ? 1000 : 5000
  const reloadSynopses = async () => {
    if (localStorage.stopChat) return
    await loadSynopses.call(this)
    pollingForSynopsisTimeout = setTimeout(reloadSynopses, frequency)
  }
  setTimeout(reloadSynopses, 100)
}

export function setFasterChatPolling(value){ fasterChatPolling = !!value }

export function stopPollingForSynopsis(){
  if (pollingForSynopsisTimeout) {
    clearTimeout(pollingForSynopsisTimeout)
    pollingForSynopsisTimeout = undefined
  }
}

export async function createMessage(chatChannelUid, {message, file}){
  const chatMessagesKey = `chat:channel:${chatChannelUid}:messages`
  const pendingChatMessagesKey = `${chatMessagesKey}:pending`
  const pendingChatMessage = {
    createdAt: Date.now(),
    uid: `pending-${Date.now()}`,
    channelUid: chatChannelUid,
    message,
  }

  if (file) pendingChatMessage.uploadId = this.takeAction('assets.upload', file)

  this.addToSet(pendingChatMessagesKey, [pendingChatMessage])

  if (file) {
    let upload = this.getState()['assets:uploads'][pendingChatMessage.uploadId]
    upload = await upload.promise
    if (!upload) {
      this.removeFromSet(pendingChatMessagesKey, [pendingChatMessage])
      return
    }
    const { type, url } = upload

    const preview = upload.preview || {}
    delete preview.url
    message = `jlinc:dm:attachment:${JSON.stringify({...preview, url, type})}`
    console.log('NEW CHAT MESSAGe', message)
  }

  try{
    const chatMessage = await api.createChatMessage(chatChannelUid, message)
    const chatMessages = Array.from(this.getState()[chatMessagesKey] || [])
    this.removeFromSet(pendingChatMessagesKey, [pendingChatMessage])
    // TODO mamnnually update the digest latest, latestUid
    this.setState({
      [chatMessagesKey]: [...chatMessages, chatMessage],
    })
  }catch(error){
    this.removeFromSet(pendingChatMessagesKey, [pendingChatMessage])
    this.addToSet(pendingChatMessagesKey, [{...pendingChatMessage, error}])
  }
}

export function retry(pendingChatMessage){
  const { channel, message } = pendingChatMessage
  this.removeFromSet(`chatMessages:${channel}:pending`, [pendingChatMessage])
  this.takeAction('chatMessages.create', channel, message)
}

export async function markAsRead(chatChannelUid){
  const chatMessages = this.getState()[`chat:channel:${chatChannelUid}:messages`] || []
  const latestChatMessage = chatMessages.filter(cm => !cm.readAt).reverse()[0]
  if (!latestChatMessage) return
  try{
    await api.markChatMessagesAsRead(latestChatMessage.uid)
  }catch(error){
    console.error(`failed to mark direct message as read`, error)
  }
}
