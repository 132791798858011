import { h } from 'preact'
import { useState, useRef } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import useToggle from 'lib/useToggleHook'
import useAutoFocus from 'lib/useAutoFocusHook'

import { useLogin, loginAs } from 'resources/auth'
import { useResettingPassword } from 'resources/password'

import Link from 'components/Link'
import Form from 'components/Form'
import Button from 'components/Button'
import Spinner from 'components/Spinner'
import TextInput from 'components/TextInput'
import Checkbox from 'components/Checkbox'
import APortalAuthForm from 'components/APortalAuthForm'
import LinkToOrganization from 'components/LinkToOrganization'
import APortalPasswordResetForm from 'components/APortalPasswordResetForm'

import './index.sass'

export default function APortalLoginForm({ className, ...props }){
  const [
    resettingPassword,
    showResetPasswordForm,
    hideResetPasswordForm,
  ] = useResettingPassword()
  return <APortalAuthForm {...{
    className: classNames('APortalLoginForm', { className }),
  }}>
    {resettingPassword
      ? <APortalPasswordResetForm onCancel={hideResetPasswordForm} />
      : <LoginForm {...{
        ...props,
        showResetPasswordForm,
        hideResetPasswordForm,
      }}/>
    }
  </APortalAuthForm>
}

APortalLoginForm.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  destinationPath: PropTypes.string,
  organization: PropTypes.shape({
    apikey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
}

function LoginForm({
  showResetPasswordForm,
  disabled,
  organization,
  destinationPath,
}){
  const joinOrganization = organization && organization.apikey
  const loginInputRef = useRef()
  useAutoFocus(loginInputRef)
  const [showPassword, , , setShowPassword] = useToggle(false)
  const [password, setPassword] = useState('')
  const {
    login,
    setLogin,
    keepMeLoggedIn,
    setKeepMeLoggedIn,
    loggingIn,
  } = useLogin()
  disabled = !!(loggingIn)
  return <Form {...{
    disabled,
    onSubmit(){
      loginAs({
        login,
        password,
        joinOrganization,
      })
    },
  }}>
    <APortalAuthForm.Intro>
      <APortalAuthForm.Logo {...{organization}}/>
      {organization
        ? <p>Login to join <LinkToOrganization {...{type: 'none', organization}}/></p>
        : <p>Login to {APP_NAME}</p>
      }
    </APortalAuthForm.Intro>
    <APortalAuthForm.LoginInputRow {...{
      ref: loginInputRef,
      value: login,
      onInput: setLogin,
    }}/>
    <Form.Row className="APortalLoginForm-passwordRow">
      <Form.Item>
        <Form.Label>PASSWORD</Form.Label>
        <Link
          className="APortalLoginForm-ForgotPasswordLink"
          type="text"
          onClick={showResetPasswordForm}
        >forgot password?</Link>
        <TextInput {...{
          value: password,
          onInput: setPassword,
          disabled,
          type: showPassword ? 'text' : 'password',
          name: 'password',
          autocomplete: 'current-password',
          required: true,
          label: false,
          lpignore: false,
        }}/>
      </Form.Item>
    </Form.Row>
    <Form.Row>
      <Form.Item>
        <Checkbox {...{
          checked: showPassword,
          onChange: setShowPassword,
          disabled,
          label: 'Show password',
        }}/>
      </Form.Item>
    </Form.Row>
    <Form.Row>
      <Form.Item>
        <Checkbox {...{
          value: keepMeLoggedIn,
          onChange: setKeepMeLoggedIn,
          disabled,
          label: 'Keep me logged in',
        }}/>
      </Form.Item>
    </Form.Row>
    <Form.Row>
      <Button submit fat block {...{
        disabled,
        type: 'primary',
        value: (
          loggingIn
            ? <span><Spinner />&nbsp;Logging in…</span>
            : 'Login' + (organization ? ` and join ${organization.name}` : '')
        ),
      }}/>
    </Form.Row>
    <APortalAuthForm.Footer>
      <span>New to {APP_NAME}? </span>
      <Link {...{
        type: 'text',
        // href: signupHref,
        pathname: '/signup',
        query: {r: destinationPath, o: joinOrganization}
      }}>Create an Account</Link>.
    </APortalAuthForm.Footer>
  </Form>
}
